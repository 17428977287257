<template>
  <!-- Container -->
  <main class="page-container">
    <!-- Header -->
    <PageHeader v-show="!isAdmin && show"></PageHeader>
    <!-- router -->
    <router-view></router-view>
  </main>
</template>
<script>
import { defineComponent, reactive, ref, watch } from 'vue'
import PageHeader from '@/views/layout/header/index.vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'app',
  components: {
    PageHeader
  },
  setup() {
    const router = reactive(useRouter())
    const store = useStore()

    const isAdmin = ref(false)
    const show = ref(false)
    const pages = ['/login', '/forget_password', '/reset_password', '/join']
    watch(router, (o) => {
      const path = o.currentRoute.fullPath
      if (path.indexOf('/admin/') > -1) {
        // admin
        isAdmin.value = true
      } else {
        isAdmin.value = false

        if (pages.indexOf(path) > -1) {
          show.value = false
        } else {
          show.value = true
        }
      }
      store.commit('setIsAdmin', isAdmin)
    })

    return {
      isAdmin,
      show
    }
  }
})
</script>
