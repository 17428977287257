<template>
  <div class="header-user">
    <template v-if="store.state.TOKEN">
      <a-popover
        :visible="popoverVisible"
        trigger="hover"
        :arrowPointAtCenter="true"
        :autoAdjustOverflow="false"
        placement="bottom"
        overlayClassName="user-info-popover"
        @visibleChange="visibleChange"
      >
        <template #content>
          <div class="btn" @click.stop="handleNavigateToChangePassword">
            Change password
          </div>
          <div class="btn" @click.stop="handleSignout">Sign Out</div>
        </template>
        <div class="info">
          <!-- <span class="avatar">
          <img class="thumb" src="https://www.luoym.com/css/images/avatar.jpg">
        </span> -->
          <user-outlined></user-outlined>
          <span class="nick-name">{{ store.state.USER_NAME || '--' }}</span>
        </div>
      </a-popover>
    </template>
    <template v-else>
      <div class="info" @click.stop="handleNavigateToLogin">
        <user-outlined></user-outlined>
        <span class="nick-name">--</span>
      </div>
    </template>
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { UserOutlined } from '@ant-design/icons-vue'
import bus from '@/utils/bus'

export default defineComponent({
  name: 'header-user',
  components: {
    UserOutlined
  },
  setup() {
    const store = useStore()
    const router = useRouter()

    const popoverVisible = ref(false)

    const visibleChange = (visible) => {
      popoverVisible.value = visible
    }

    const handleNavigateToChangePassword = () => {
      popoverVisible.value = false
      if (store.state.IS_ADMIN) {
        bus.emit('showChangePassword')
      } else {
        router.push({ path: '/change_password' })
      }
    }

    const handleSignout = () => {
      store.commit('setToken', '')
      store.commit('setUsername', '')
      store.commit('setUserid', '')
      popoverVisible.value = false
      if (store.state.IS_ADMIN) {
        router.push({ path: '/admin/login' })
      } else {
        router.push({ path: '/login' })
      }
    }

    const handleNavigateToLogin = () => {
      router.push({ path: '/login' })
    }

    return {
      store,
      popoverVisible,
      visibleChange,
      handleNavigateToChangePassword,
      handleSignout,
      handleNavigateToLogin
    }
  }
})
</script>
