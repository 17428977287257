<template>
  <div class="header-menu">
    <router-link to="/" class="item" :class="{ active: currentKey == '1' }">
      <dashboard-outlined></dashboard-outlined>
      <span class="title">Dashboard</span>
    </router-link>
    <router-link
      to="/annotate"
      class="item"
      :class="{
        active: currentKey == '2' || currentKey == '3' || currentKey == '4'
      }"
    >
      <AppstoreOutlined></AppstoreOutlined>
      <span class="title">Project</span>
    </router-link>
    <!-- <router-link
      to="/upload"
      class="item"
      :class="{ active: currentKey == '2' }"
    >
      <upload-outlined></upload-outlined>
      <span class="title">Upload</span>
    </router-link>
    <router-link
      to="/annotate"
      class="item"
      :class="{ active: currentKey == '3' }"
    >
      <gateway-outlined></gateway-outlined>
      <span class="title">Annotate</span>
    </router-link>
    <router-link
      to="/datasetList"
      class="item"
      :class="{ active: currentKey == '4' }"
    >
      <cluster-outlined></cluster-outlined>
      <span class="title">Dataset</span>
    </router-link> -->
    <router-link to="/role" class="item" :class="{ active: currentKey == '5' }">
      <team-outlined></team-outlined>
      <span class="title">Role</span>
    </router-link>
  </div>
</template>
<script>
import { defineComponent, watch, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import {
  DashboardOutlined,
  TeamOutlined,
  AppstoreOutlined
} from '@ant-design/icons-vue'

export default defineComponent({
  name: 'header-menu',
  components: {
    DashboardOutlined,
    TeamOutlined,
    AppstoreOutlined
  },
  setup() {
    const router = reactive(useRouter())

    const currentKey = ref('')

    watch(router, (o) => {
      const nav = o.currentRoute.meta ? o.currentRoute.meta.nav : ''

      if (nav === 'dashboard') {
        currentKey.value = '1'
      } else if (nav === 'upload') {
        currentKey.value = '2'
      } else if (nav === 'annotate') {
        currentKey.value = '3'
      } else if (nav === 'dataset') {
        currentKey.value = '4'
      } else if (nav === 'role') {
        currentKey.value = '5'
      } else {
        currentKey.value = ''
      }
    })

    return {
      currentKey
    }
  }
})
</script>
