import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {
  Button,
  message,
  Popover,
  Carousel,
  Modal,
  Input,
  Select,
  Upload,
  Tabs,
  Popconfirm,
  Checkbox,
  Anchor,
  Pagination,
  Spin,
  Radio,
  Form,
  DatePicker,
  Badge,
  Drawer,
  Menu,
  Table,
  Progress
} from 'ant-design-vue'
// global CSS
import '@/utils/styles/global.scss'

const app = createApp(App)

app.use(Button)
app.use(Popover)
app.use(Carousel)
app.use(Modal)
app.use(Input)
app.use(Select)
app.use(Upload)
app.use(Tabs)
app.use(Popconfirm)
app.use(Checkbox)
app.use(Anchor)
app.use(Pagination)
app.use(Spin)
app.use(Radio)
app.use(Form)
app.use(DatePicker)
app.use(Badge)
app.use(Drawer)
app.use(Menu)
app.use(Table)
app.use(Progress)
message.config({
  maxCount: 1
})
app.config.globalProperties.$message = message
app.use(store)
app.use(router)

router.beforeEach(function (to, from, next) {
  store.commit('setTimeXF', null)
  store.commit('clearAxiosArr')
  if (
    to.fullPath.indexOf('/login') === -1 &&
    to.fullPath.indexOf('/admin/') > -1
  ) {
    if (store.state.ROLE_TYPE !== '1') {
      message.error('No Permission')
      next({ path: '/login' })
    }
  }
  next()
})

app.mount('#app')
