import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/login/index.vue')
  },
  {
    path: '/forget_password',
    name: 'forget_password',
    component: () =>
      import(
        /* webpackChunkName: "forgetPassword" */ '../views/forget_password/index.vue'
      )
  },
  {
    path: '/change_password',
    name: 'change_password',
    component: () =>
      import(
        /* webpackChunkName: "forgetPassword" */ '../views/change_password/index.vue'
      )
  },
  {
    path: '/reset_password',
    name: 'reset_password',
    component: () =>
      import(
        /* webpackChunkName: "resetPassword" */ '../views/reset_password/index.vue'
      )
  },
  {
    path: '/join',
    name: 'join',
    component: () =>
      import(/* webpackChunkName: "resetPassword" */ '../views/join/index.vue')
  },
  {
    path: '/',
    name: 'dashboard-index',
    meta: {
      nav: 'dashboard'
    },
    component: () =>
      import(/* webpackChunkName: "dashboard" */ '../views/dashboard/index.vue')
  },
  {
    path: '/upload',
    name: 'upload-index',
    meta: {
      nav: 'upload'
    },
    component: () =>
      import(/* webpackChunkName: "upload" */ '../views/upload/index.vue')
  },
  {
    path: '/annotate',
    name: 'annotate-index',
    meta: {
      nav: 'annotate'
    },
    component: () =>
      import(/* webpackChunkName: "annotate" */ '../views/annotate/index.vue')
  },
  {
    path: '/annotate/detail/:id',
    name: 'annotate-detail',
    meta: {
      nav: 'annotate'
    },
    component: () =>
      import(/* webpackChunkName: "annotate" */ '../views/annotate/detail.vue')
  },
  {
    path: '/dataset/:id',
    name: 'dataset-index',
    meta: {
      nav: 'dataset'
    },
    component: () =>
      import(/* webpackChunkName: "dataset" */ '../views/dataset/index.vue')
  },
  {
    path: '/datasetList',
    name: 'datasetList-index',
    meta: {
      nav: 'datasetList'
    },
    component: () =>
      import(
        /* webpackChunkName: "datasetList" */ '../views/datasetList/index.vue'
      )
  },
  {
    path: '/role',
    name: 'role-index',
    meta: {
      nav: 'role'
    },
    component: () =>
      import(/* webpackChunkName: "role" */ '../views/role/index.vue')
  },
  // admin
  {
    path: '/admin/login',
    name: 'admin-login-index',
    component: () =>
      import(/* webpackChunkName: "admin" */ '../views/login/index.vue')
  },
  {
    path: '/admin/user/management',
    name: 'admin-user-management-index',
    component: () =>
      import(
        /* webpackChunkName: "admin" */ '../views/user_management/index.vue'
      )
  },
  {
    path: '/admin/role/management',
    name: 'admin-role-management-index',
    component: () =>
      import(
        /* webpackChunkName: "admin" */ '../views/role_management/index.vue'
      )
  },
  // 404
  {
    path: '/:pathMatch(.*)*',
    name: 'error-404',
    component: () =>
      import(/* webpackChunkName: "error" */ '../views/error/404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
