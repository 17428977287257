<template>
  <header class="page-header">
    <section class="content-section flex-h">
      <!-- Logo -->
      <router-link to="/" class="header-logo">G.E.M</router-link>
      <!-- Nav Menu -->
      <NavMenu></NavMenu>
      <!-- User Info -->
      <User></User>
    </section>
  </header>
</template>
<script>
import { defineComponent } from 'vue'
import NavMenu from './components/menu.vue'
import User from './components/user.vue'

export default defineComponent({
  name: 'layout-header',
  components: {
    NavMenu,
    User
  },
  setup() {
    return {}
  }
})
</script>
