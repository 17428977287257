import { createStore } from 'vuex'

export default createStore({
  state: {
    TOKEN: localStorage.getItem('TOKEN') || '', // Token
    USER_NAME: localStorage.getItem('USER_NAME') || '', // User_name
    USER_ID: localStorage.getItem('USER_ID') || '', // User_id

    ANNOTATE_SOURCE_TYPE: '', // annotate list drag source type

    IS_ADMIN: '', // is admin page

    ROLE_TYPE: localStorage.getItem('ROLE_TYPE') || '0', // 0 - nomal 1 - admin

    ADMIN_MENU_COLLAPSED: false, //

    AXIOS_ARR: [], // store cancel token

    TIMEOUT_XF: null,

    EXPORT_FILE_NAME: ''
  },
  getters: {},
  mutations: {
    setToken(state, token) {
      localStorage.setItem('TOKEN', token)
      state.TOKEN = token
    },
    setUsername(state, username) {
      localStorage.setItem('USER_NAME', username)
      state.USER_NAME = username
    },
    setUserid(state, userid) {
      localStorage.setItem('USER_ID', userid)
      state.USER_ID = userid
    },

    setAnnotateSourceType(state, type) {
      state.ANNOTATE_SOURCE_TYPE = type
    },

    setIsAdmin(state, status) {
      state.IS_ADMIN = status
    },

    setRoleType(state, type) {
      localStorage.setItem('ROLE_TYPE', type)
      state.ROLE_TYPE = type
    },

    setAdminMenuCollapsed(state, status) {
      state.ADMIN_MENU_COLLAPSED = status
    },

    setAxiosArr(state, cancelAjax) {
      state.AXIOS_ARR.push(cancelAjax.cancelToken)
    },

    clearAxiosArr(state) {
      state.AXIOS_ARR.forEach((item) => {
        item('Request cancel')
      })
      state.AXIOS_ARR = []
    },

    setTimeXF(state, timexf) {
      if (timexf) {
        state.TIMEOUT_XF = timexf
      } else {
        clearTimeout(state.TIMEOUT_XF)
      }
    },

    setExportFileName(state, name) {
      state.EXPORT_FILE_NAME = name
    }
  },
  actions: {
    setToken(context, token) {
      context.dispatch('setToken', token)
    },
    setUsername(context, username) {
      context.dispatch('setUsername', username)
    },
    setUserid(context, userid) {
      context.dispatch('setUserid', userid)
    },

    setAnnotateSourceType(context, type) {
      context.dispatch('setAnnotateSourceType', type)
    },

    setIsAdmin(context, status) {
      context.dispatch('setIsAdmin', status)
    },

    setRoleType(context, type) {
      context.dispatch('setRoleType', type)
    },

    setAdminMenuCollapsed(context, status) {
      context.dispatch('setAdminMenuCollapsed', status)
    },

    setAxiosArr(context, cancelAjax) {
      context.dispatch('setAxiosArr', cancelAjax)
    },

    clearAxiosArr(context) {
      context.dispatch('clearAxiosArr')
    },

    setTimeXF(context, timexf) {
      context.dispatch('setTimeXF', timexf)
    },

    setExportFileName(context, name) {
      context.dispatch('setExportFileName', name)
    }
  },
  modules: {}
})
